@use 'themes/_mixins.scss' as mixin;
@use 'themes/_variables.scss' as variable;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://cdn.jsdelivr.net/npm/@sweetalert2/theme-material-ui/material-ui.css');

// ::-webkit-scrollbar {
//   width: 9px;
// }

// *:hover::-webkit-scrollbar-thumb {
//   background: #2a2b2b;
//   border-radius: 6px;
// }

// *::-webkit-scrollbar-thumb {
//   background: #181919;
//   border-radius: 6px;
// }

a, a:visited, a:hover, a:active {
  color: inherit;
  text-decoration: none;
}

.swal2-container {
  z-index: 1400 !important;
  font-family: "Montserrat", "sans-serif";
}

.read-more-list {
  @include mixin.readMore;
  justify-content: flex-end;
}

.read-more-list-left {
  @include mixin.readMore;
  justify-content: flex-start;
}

.diagonal-strike {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnPgogIDxyZWN0IHdpZHRoPSc4JyBoZWlnaHQ9JzgnIGZpbGw9JyNmZmYnLz4KICA8cGF0aCBkPSdNMCAwTDggOFpNOCAwTDAgOFonIHN0cm9rZS13aWR0aD0nMC41JyBzdHJva2U9JyNhYWEnLz4KPC9zdmc+Cg==");
  background-repeat: repeat;
}

.form-input-small {
  & [class*="MuiInputBase-root"] {
    font-size: 0.725rem
  }
  & [class*="MuiFormControlLabel-label"] {
    font-size: 0.725rem
  }
}

.user-cell {
  white-space: pre-line;
}

.float-right {
  display: flex;
  justify-content: flex-end;
}

.float-left {
  display: flex;
  justify-content: flex-start;
}

.link-redirect {
  color: variable.$linkColor;
  font-weight: 600;
  cursor: pointer;
}

.user-cell-style {
  white-space: pre-line;
}

input.Mui-disabled {
  background-color: #f3f3f2;
}

.MuiInputBase-root.Mui-disabled {
  background-color: #f3f3f2;
  textarea.Mui-disabled {
    background-color: #f3f3f2;
  }
}

.wsp-typeahead {
  .Mui-disabled {
    background-color: #f3f3f2;
    .MuiChip-root.Mui-disabled {
      background-color: map-get(variable.$theme-colors, 'primaryMain');
      color: white;
      opacity: 1;
    }
  }
}

.dummy-disabled-input {
  background-color: #f3f3f2;
  border-radius: 4px;
  border: 1px solid rgba(180, 180, 179, 1);
  color: rgba(0, 0, 0, 1);
  height: 33px;
  font-size: 0.725rem;
  display: flex;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
}

mgt-login {
  --font-size: 12px;
  --font-weight: 600;
  --weight: '100%';
  --height: 40px;
  --margin: 0;
  --padding: 12px 20px;
  --button-color: white;
  --button-color--hover: white;
  --button-background-color: transparent;
  --button-background-color--hover: transparent;
  --popup-background-color: white;
  --popup-command-font-size: 12px;
  --popup-color: black;
}

.MuiFormControlLabel-label.Mui-disabled {
  color: grey !important;
}