@mixin centerVertical {
  display: flex;
  align-items: center;
}

@mixin centerVerticalAndHorizontal {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin gridBorder {
  border: 1px solid grey;
}

@mixin readMore {
  color: green !important;
  font-style: italic;
  text-decoration: underline;
  display: flex;
}